import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Journal extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Journal - Kuokoa Studio" />
        <article className="journal">
          <h1>Lab Notes</h1>
          <section className="content">
            <p>Coming soon...</p>
          </section>
          {
          // {data.entries.edges.map(({ node }) => {
          //   const md = node.children[0]
          //   const title = md.frontmatter.title || md.fields.slug
          //   return (
          //     <section className="entry" key={md.fields.slug}>
          //       <h2><Link to={`journal` + md.fields.slug}>{title}</Link></h2>
          //       <small>{md.frontmatter.date}</small>
          //       <p dangerouslySetInnerHTML={{
          //         __html: md.frontmatter.description || md.excerpt,
          //       }} />
          //     </section>
          //   )
          // })}
          }
        </article>
      </Layout>
    )
  }
}

export default Journal

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    entries: allFile(filter: {
      sourceInstanceName: {eq: "journal"},
      extension: {eq: "md"}
    }, sort: {
      fields: childMarkdownRemark___frontmatter___date,
      order: DESC
    }) {
      edges {
        node {
          children {
            ... on MarkdownRemark {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
              }
            }
          }
        }
      }
    }
  }
`
